// Dependencies
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

// Components
import { closeSVG, gridSVG } from '../../assets/icons'
import FadeIn from '../Animation/FadeIn'

// Styles
import {
  InternalHeader,
  InternalHeaderContent,
  LogoLink,
  LinkWithIcon,
} from './InternalHeader.styled'

const PAGE_SCROLL_POSITION_TO_ALLOW_MENU_VISIBILITY_TO_CHANGE = 200
const OFFSET_SCROLL_TO_CHANGE_VISIBILITY = 20

class Header extends Component {
  constructor(props) {
    super(props)

    this.direction = 0
    this.previousScrollPosition = 0
    this.positionAtLastChangeOfDirection = 0

    this.state = {
      navVisible: true,
    }
    this.resizeHandler = this.resizeHandler.bind(this)
    this.scrollHandler = this.scrollHandler.bind(this)
  }

  componentDidMount() {
    this.doScroll = _.throttle(this.scrollHandler, 200)
    this.doResize = _.throttle(this.resizeHandler, 200)
    window.addEventListener('scroll', this.doScroll)
    window.addEventListener('resize', this.doResize)
    this.scrollHandler()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.doScroll)
    window.removeEventListener('resize', this.doResize)
  }

  resizeHandler() {
    this.scrollHandler()
  }

  scrollHandler() {
    const { navVisible } = this.state

    let stateChanged = false
    const newState = {}
    const scrollTop =
      document.documentElement && document.documentElement.scrollTop
        ? document.documentElement.scrollTop
        : document.body.scrollTop

    // If the current scroll position is higher than the previous,
    // it means the direction is downwards
    // If the current set direction is not downwards, set it to be downwards,
    // also setting the position at changing of direction
    if (scrollTop > this.previousScrollPosition && this.direction > -1) {
      this.direction = -1
      this.positionAtLastChangeOfDirection = scrollTop

      // If the current scroll position is lower than the previous,
      // it means the direction is upwards
      // If the current set direction is not upwards, set it to be upwards,
      // also setting the position at changing of direction
    } else if (scrollTop < this.previousScrollPosition && this.direction < 1) {
      this.direction = 1
      this.positionAtLastChangeOfDirection = scrollTop
    }

    // If the offset between position at last change of direction and
    // current scroll position is higher than OFFSET_SCROLL_TO_CHANGE_VISIBILITY
    if (
      Math.abs(scrollTop - this.positionAtLastChangeOfDirection) >
      OFFSET_SCROLL_TO_CHANGE_VISIBILITY
    ) {
      newState.navVisible = !(this.direction < 0)
      stateChanged = true
    }

    // Always show nav if scrolltop position is less than offset
    if (
      scrollTop < PAGE_SCROLL_POSITION_TO_ALLOW_MENU_VISIBILITY_TO_CHANGE &&
      (!navVisible || !newState.navVisible)
    ) {
      newState.navVisible = true
    }

    // Set state if any property has been added to newState Object
    if (Object.keys(newState).length) this.setState(newState)

    // Cache current scroll position for next iteration
    this.previousScrollPosition = scrollTop
  }

  render() {
    const { navVisible } = this.state

    let headerClass = ['nav-header']
    if (!navVisible) headerClass.push('hide-nav')

    return (
      <header className={headerClass.join(' ')}>
        <InternalHeader>
          <FadeIn>
            <InternalHeaderContent>
              <LogoLink
                to="/"
                href
                onClick={() => {
                  trackCustomEvent({
                    category: 'Internal Header',
                    action: 'Click',
                    label: 'Logo',
                  })
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: this.props.logoSvg }} />
              </LogoLink>

              {this.props.showGrid ? (
                <>
                  <LinkWithIcon
                    to="/"
                    href
                    dangerouslySetInnerHTML={{ __html: closeSVG }}
                    style={{
                      marginLeft: '10px',
                    }}
                    onClick={() => {
                      trackCustomEvent({
                        category: 'Internal Header',
                        action: 'Click',
                        label: 'Close',
                      })
                    }}
                  />
                  <LinkWithIcon
                    href
                    to="/work"
                    dangerouslySetInnerHTML={{ __html: gridSVG }}
                    onClick={() => {
                      trackCustomEvent({
                        category: 'Internal Header',
                        action: 'Click',
                        label: 'Grid',
                      })
                    }}
                  />
                </>
              ) : (
                <LinkWithIcon
                  to="/"
                  href
                  dangerouslySetInnerHTML={{ __html: closeSVG }}
                  onClick={() => {
                    trackCustomEvent({
                      category: 'Internal Header',
                      action: 'Click',
                      label: 'Close',
                    })
                  }}
                />
              )}
            </InternalHeaderContent>
          </FadeIn>
        </InternalHeader>
      </header>
    )
  }
}

// Components PropTypes
Header.propTypes = {
  showGrid: PropTypes.bool,
  logoSvg: PropTypes.string,
}
Header.defaultProps = {
  showGrid: false,
  logoSvg: '',
}

export default Header
