// Dependencies
import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from '../components/Helmet'

// Components
import ScaleDown from '../components/Animation/ScaleDown'
import Header from '../components/Header/InternalHeader'
import ProjectsList from '../components/Work/ProjectsList'
import Layout from '../components/layout'

// Styles
import { Section, SectionTitle } from '../components/common/Section.styled'

const Work = props => {
  const siteInfo = get(props, 'data.allContentfulGeneral.nodes[0]')
  const projects = get(props, 'data.allContentfulProject.nodes')

  return (
    <Layout>
      <div>
        <Helmet
          {...{ ...siteInfo, websiteTitle: siteInfo.websiteTitle + ' - Work' }}
        />
        <div>
          <div>
            <Header logoSvg={siteInfo.logoSvg.logoSvg} />
            <Section>
              <br />
              <br />
              <br />
              <ScaleDown>
                <SectionTitle>work</SectionTitle>
              </ScaleDown>
            </Section>
            <Section>
              <ProjectsList
                projects={projects}
                title="Projects"
                shouldAllowExtra={true}
              />
            </Section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Work

export const pageQuery = graphql`
  query WorkQuery {
    allContentfulGeneral {
      nodes {
        ...general
      }
    }

    allContentfulProject {
      nodes {
        ...project
      }
    }
  }
`
