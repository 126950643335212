// Dependencies
import styled from 'styled-components'
import { Link } from 'gatsby'

// Constants
import colors from '../../constants/colors'
import { maxWidth } from '../../constants/general'
import { sizesMin, sizesMax } from '../../constants/breakpoints'

export const InternalHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  z-index: 9998;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  background: ${colors.white};
  height: 114px;
  padding-top: 5px;
  transition: transform 0.3s linear;

  @media (max-width: ${sizesMax.tablet}) {
    height: 84px;
  }
  @media (max-width: ${sizesMax.mobileL}) {
    height: 74px;
  }

  :before {
    content: '';
    height: 5px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: ${colors.violet};
  }

  @media screen and (min-width: 1024px) {
    .nav-header & {
      transform: translate3d(0px, 0px, 0);
    }
    .nav-header.hide-nav & {
      transform: translate3d(0px, -120px, 0);
    }
  }
  @media screen and (max-width: 1023px) {
    .nav-header & {
      transform: translate3d(0px, 0px, 0);
    }
    .nav-header.hide-nav & {
      transform: translate3d(0px, -90px, 0);
    }
  }
`

export const InternalHeaderContent = styled.div`
  ${maxWidth};
  padding: 30px;

  @media (max-width: ${sizesMax.tablet}) {
    padding: 20px 30px;
  }

  @media (max-width: ${sizesMax.mobileL}) {
    padding: 15px 5vw;
  }

  aside {
    background: red;
  }
`

export const LogoLink = styled(Link)`
  height: 50px;
  width: 150px;
  display: inline-block;
  position: relative;

  @media (max-width: ${sizesMax.tablet}) {
    height: 40px;
    width: 124px;
  }

  svg {
    position: relative;
    display: block;
    height: 50px;
    width: 150px;

    .logo_name path {
      fill: ${colors.violet};
    }

    .logo_title path {
      fill: ${colors.violet};
    }

    @media (max-width: ${sizesMax.tablet}) {
      height: 40px;
      width: 124px;
    }
  }
`

export const LinkWithIcon = styled(Link)`
  width: 50px;
  height: 50px;
  display: block;
  position: relative;
  float: right;
  background: ${colors.lighterViolet};
  transition: all 0.2s ease-in;
  cursor: pointer;

  @media (max-width: ${sizesMax.tablet}) {
    width: 40px;
    height: 40px;
  }

  svg {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    * {
      fill: ${colors.black};
    }
  }

  &:focus,
  &:hover {
    background: ${colors.violet};

    svg {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      * {
        fill: ${colors.white};
      }
    }
  }
`
